<template>
  <div>
    <Navigation />

    <div class="wrap-box">
      <h3 class="heading">Контакти</h3>
    </div>
    <div class="wrap-box">
      <div class="contacts-info-block">
        <p>
          <span>Повна назва Центру:</span> КОМУНАЛЬНЕ НЕКОМЕРЦІЙНЕ ПІДПРИЄМСТВО
          «ЦЕНТР ПЕРВИННОЇ МЕДИКО-САНІТАРНОЇ ДОПОМОГИ №2» ДЕСНЯНСЬКОГО РАЙОНУ
          М.КИЄВА
        </p>
        <p>
          <span>Скорочено:</span> КНП «ЦПМСД №2» Деснянського району м. Києва
        </p>
        <p>
          <span>Індекс:</span> 02226
        </p>
        <p>
          <span>Адреса:</span> м. Київ, вул. Рональда Рейгана, 19
        </p>
        <p>
          <span>Код ЄДРПОУ:</span> 21467676
        </p>
        <p>
          <span>Е-mail:</span>
          <a href="mailto:desnacpmsd2@kyivcity.gov.ua">desnacpmsd2@kyivcity.gov.ua</a>
        </p>
        <p>
        <span>Телефони:</span>
        <ul class="phone-list">
          <li>
            Реєстратура – вул. Рональда Рейгана, 19,
            <a href="tel:19 (073) 537-15-60">19 (073) 537-15-60 (багатоканальний);</a>
          </li>
          <li>
            Черговий кабінет:
            <a href="tel:(073) 537-16-60">(073) 537-16-60;</a>
          </li>
          <li>
            Приймальна директора:
            <a href="tel:(073) 537-15-10">(073) 537-15-10;</a>
          </li>
        </ul>
        </p>
        <p>
          <span>Facebook:</span>
          <a href="https://www.facebook.com/cpmsd2desna">facebook.com/cpmsd2desna</a>
        </p>
      </div>
    </div>
    <div class="wrap-box">
      <h3 class="heading">Карта</h3>
    </div>
    <div class="wrap-box">
      <div id="map_block" class="map-block">
      </div>
    </div>
  </div>
</template>
<script>
import Navigation from "@/components/Navigation";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
mapboxgl.accessToken =
  "pk.eyJ1IjoiY3Btc2QtZGVzbmEiLCJhIjoiY2t3b21hdW11MDJoeTJ2cWhtM2xud2RyaiJ9.FaZyGqBIApes84DUdJ95Ig";

export default {
  name: "Contacts",
  components: {
    Navigation
  },
  mounted() {
    this.$nextTick(() => {
      const map = new mapboxgl.Map({
        container: "map_block", // container ID
        style: "mapbox://styles/mapbox/streets-v11", // style URL
        center: [30.5978, 50.5099], // starting position [lng, lat]
        zoom: 12, // starting zoom,
        minZoom: 8
        // interactive: false,
      });

      const coordinates = [
        {
          coord: [30.5966, 50.5092],
          title:
            "<strong>вул. Рональда Рейгана 19:</strong><br> Амбулаторії №1,2,4,5"
        },
        {
          coord: [30.6062, 50.5056],
          title:
            "<strong>пр. Червоної Калини, 18А:</strong><br> Амбулаторії №7,8"
        },
        {
          coord: [30.5838, 50.5133],
          title: "<strong>вул. Радосинська, 18:</strong> <br> Амбулаторія №3"
        },
        {
          coord: [30.5894, 50.5083],
          title: "<strong>вул. Бальзака Оноре 22:</strong><br> Амбулаторія №6"
        }
      ];

      coordinates.forEach(item => {
        const el = document.createElement("div");
        el.style.width = "50px";
        el.style.height = "50px";
        el.style.backgroundPosition = "center";
        el.style.backgroundRepeat = "no-repeat";
        el.style.backgroundSize = "contain";
        // el.style.backgroundColor = "red";
        el.style.cursor = "pointer";
        el.style.borderRadius = "50%";
        el.style.backgroundImage = "url('/assets/contacts/placeholder.png')";

        // make a marker for each feature and add to the map
        const popup = new mapboxgl.Popup().setHTML(`<p>${item.title}</p>`);

        new mapboxgl.Marker(el)
          .setLngLat(item.coord)
          .setPopup(popup)
          .addTo(map);
        // .togglePopup();
      });
    });
  }
};
</script>
<style scoped>
.wrap-box {
  max-width: 1250px;
  width: calc(100% - 30px);
  margin: 0 auto 30px auto;
  padding: 1px 0;
}

main.contacts {
  display: flex;
  flex-direction: column;
  max-width: 1250px;
  width: calc(100% - 30px);
  margin: 20px auto 60px auto;
  align-items: flex-start;
}

h3.heading {
  color: #4e5154;
  margin: 30px auto 30px 40px;
  font-size: 34px;
  text-align: left;
}

h3.heading::after {
  content: " ";
  display: block;
  width: 100%;
  max-width: 200px;
  height: 3px;
  top: 100%;
  left: 0;
  background: #aec3de;
}

.contacts-info-block {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 10px auto;
  padding: 30px;
  box-shadow: 3px 5px 13px 0 rgba(0, 0, 0, 0.26);
  border-radius: 10px;
  overflow: hidden;
  background: #e3e3e3;
}

.contacts-info-block p {
  font-size: 16px;
  align-self: flex-start;
  text-align: left;
  margin: 5px 0;
  line-height: 130%;
}

.contacts-info-block p span {
  font-size: 18px;
  text-decoration: 2px solid underline;
  margin-right: 7px;
  font-weight: bold;
}
.contacts-info-block p a {
  color: rgb(17, 17, 212);
}

.contacts-info-block p ul {
  margin: 10px 0 10px 15px;
}

.contacts-info-block p ul li {
  list-style: disc;
}

.map-block {
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  margin: 10px auto;
  padding: 30px;
  box-shadow: 3px 5px 13px 0 rgba(0, 0, 0, 0.26);
  border-radius: 10px;
  overflow: hidden;
  background: #e3e3e3;
}

@media screen and (max-width: 600px) {
  h3.heading {
    margin: 20px auto 20px 30px;
    font-size: 28px;
  }

  .contacts-info-block p span {
    font-size: 17px;
  }
}
</style>
